section.iconteaser {
  margin: 30px 0 60px 0;
}

.iconteaser__image {
  position: relative;
  padding-bottom: 60%;
  background: #F1F1F1;
  margin-bottom: 15px;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}

.iconteaser__title {
  padding-bottom: 10px;
  border-bottom: 1px solid $brand-primary;
  margin-bottom: 15px;

  h4 {
    margin-bottom: 0;
    font-weight: 600;
    line-height: 23px;
  }
}

.iconteaser__text {
  font-weight: 300;
  line-height: 25px;
}

.jobad {
  margin: 35px 0 50px 0;
  @media (max-width: 767px) {
    margin-top: 0;
  }

  p {
    line-height: 25px;
  }
}

.jobad .btn--mail svg {
  position: relative;
  top: 2px;
  margin-right: 10px;
}

.jobprofile {
  margin: 50px 0 50px 0;

  .panel-group {
    margin-bottom: 50px;
  }

  .panel-heading {
    background: #2AA6DF;
    color: #FFF;
    margin-bottom: 20px;

    h4 {
      margin: 0!important;
      line-height: 28px;

      a {
        position: relative;
        display: block;
        padding: 11px 13px;
        font-size: 22px!important;
        font-weight: 600!important;
        color: #FFF;

        &:before {
          content: "";
          display: block;
          position: absolute;
          right: 21px;
          top: 17px;
          width: 1px;
          height: 16px;
          background: #FFF;
          opacity: 0;
        }

        &:after {
          content: "";
          display: block;
          position: absolute;
          right: 13px;
          top: 24px;
          height: 1px;
          width: 17px;
          background: #FFF;
        }

        &[aria-expanded="false"] {

          &:before {
            opacity: 1;
          }
        }
      }
    }
  }

  ul {
    list-style: none;
  }

  li {
    position: relative;
    line-height: 25px;
    list-style: none;
    padding-bottom: 0!important;
    margin-bottom: 0!important;

    &:before {
      content: ""!important;
      display: inline-block;
      width: 5px!important;
      height: 5px!important;
      background: #D51818!important;
      border-radius: 100% !important;
      border: none!important;
      top: 10px!important;
      left: 15px!important;
    }
  }
}

.interferer {
  margin: 50px calc(50% - 50vw) 100px calc(50% - 50vw);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);;

  img {
    min-height: 100px;
    width: 100%;
    object-fit: cover;
  }
}

.textimg2col {
  margin: 80px 0;

  p {
    line-height: 25px;
  }

  img {
    width: 100%;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);;
  }
}

.benefits {
  margin: 50px 0 50px 0;
}

.benefits__item {
  border-radius: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 148px;
  height: 148px;

  @media (max-width: 767px) {
    width: 125px;
    height: 125px;
  }

  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  margin: 0 auto;

  img {
    margin: 0!important;
    width: 50%;
    height: 50%;
  }
}

.benefits__item-title {
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
}

.benefits>.row>div {
  margin-bottom: 50px;
}

.benefits__title {
  position: relative;
  text-align: center;
  margin-bottom: 40px;

  h2 {
    display: inline-block;
    background: #FFF;
    padding: 0 30px;
    margin: 0;
    z-index: 2;
    position: relative;
  }

  &:before {
    content: "";
    width: 100%;
    height: 1px;
    background: $brand-primary;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    z-index: 1;
  }
}

.jobcontact {
  margin: 100px calc(50% - 50vw) 0  calc(50% - 50vw);
  padding: 50px 0 0 0;
  background: #F1F1F1;

  .row>div {
    padding-bottom: 50px;
  }

  h3 {
    color: #333333!important;
    font-size: 2rem!important;
    font-weight: 600!important;
    margin-bottom: 10px;
  }

  p {
    margin: 0!important;
    line-height: 25px;
  }
}

.jobcontact__mail {
  font-weight: 600;

  svg {
    position: relative;
    top: 3px;
    margin: 10px 5px 0 0;
  }
}

.jobcontact__phone {

  svg {
    position: relative;
    top: 4px;
    margin: 0 5px 0 0;
  }
}

@media (min-width: $screen-tablet) {

  .jobcontact {
    margin: 50px calc(50% - 50vw);
  }
}
