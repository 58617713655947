//
// Z-Index Scale
// --------------------------------------------------
$zIndex-1:   100;
$zIndex-2:   200;
$zIndex-3:   300;
$zIndex-4:   400;
$zIndex-5:   500;
$zIndex-6:   600;
$zIndex-7:   700;
$zIndex-8:   800;
$zIndex-9:   900;
$zIndex-10: 1000;


// Z-Index Applications
// --------------------------------------------------
$zIndex-1--exampleClass:        $zIndex-1;