.lang__dropbtn {
  color: white;
  padding: 8px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  background: none;
}
// #0092d5
.lang__dropdown {
  //position: relative;
  display: inline-block;
  position: absolute;
  top: 8px;
  right: 70px;
  margin-top: 8px;
}

.lang__dropdown img {
  width: 30px;
}

.lang__dropdown--arrow {
  width: 14px!important;
  margin-left: 5px;
}

.lang__dropdown-content {
  display: none;
  position: absolute;
  background-color: #FFF;
  min-width: 66px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  //z-index: 1;

  &.open {
    z-index: 1;
  }
}

.lang__dropdown-content a {
  color: black;
  padding: 8px 8px 0;
  text-decoration: none;
  display: block;
}

.lang__dropdown-content a:last-of-type {
  padding-bottom: 8px;
}

//.lang__dropdown-content a:hover {background-color: #f1f1f1}

//.lang__dropdown:hover .lang__dropdown-content {
//  display: block;
//}


@media (min-width: 768px) {
  .lang__dropdown {
    display: none;
  }
}
