// Custom (non-bootsrap) Project Styles - must be imported first in case of SASS
@import "jquery.fancybox.scss";
@import "connectiv/z-index.scss";
@import "connectiv/sprite.scss";
@import "connectiv/custom-vars.scss";
@import "connectiv/conn-basics.scss";

// Font-Awesome
// Wenn nur einzelne Icons verwendet werden, diese als SVG extrahieren (z.B. mit der Chrome-App "IcoMoon")
//@import "font-awesome/font-awesome.scss";

//Bootstrap
//enthält in dieser Einstellung nur häufig benötigte Dateien (bei Bedarf zusätzliche Kompontenten in der _bootstrap.scss einkommentieren)
@import "bootstrap/bootstrap";

//Slick Slider
@import "slick/slick.scss";
.slick-slide:focus { outline: none; }
.slick-arrow { position: absolute; border: none; background: transparent; font-size: 0; width: 44px; height: 44px; background-repeat: no-repeat; background-position: center; top: 50%; transform: translateY(-50%); z-index: 100; opacity: 0.5; transition: all 0.3s; outline: none;}
.slick-arrow:hover { opacity: 1; }
.slick-prev { left: 0; background-image: url('../img/angle-left.svg'); }
.slick-next { right: 0; background-image: url('../img/angle-right.svg'); }
.slick-dots { position: absolute; width: 100%; text-align: center; padding: 0; }
.slick-dots li { list-style-type: none; display: inline-block; }
.slick-dots li button { border: none; width: 15px; height: 4px; -ms-transform: skewX(-30deg); -webkit-transform: skewX(-30deg); transform: skewX(-30deg); font-size: 0; margin: 5px; background-color: #ababab; }
.slick-dots li button:focus { outline: none; }
.slick-dots li.slick-active button { background-color: $brand-primary; }
.slick-dots li button:hover { opacity: 0.75; }
.content-slider .slick-prev { left: -28px }
.content-slider .slick-next { right: -28px }
.content-slider--prod .slick-prev {left: 7px; top: 38%}
.content-slider--prod .slick-next {right: 7px; top: 38%}
.content-slider { margin: 0 0-$grid-gutter-width/2 0; }
.content-slider__item { margin: 0 $grid-gutter-width/2; }
.content-slider--f .slick-prev { background-image: url('../img/angle-left-white.svg'); }
.content-slider--f .slick-next { background-image: url('../img/angle-right-white.svg'); }
@media (max-width: 550px) {.content-slider--prod { margin-bottom: 0 }}

//Animatiertes Menüicon
.menu-icon { width: 44px; height: 44px; position: relative; transform: rotate(0deg); transition: .5s ease-in-out; cursor: pointer; background: none; border: none; }
.menu-icon span { display: block; position: absolute; height: 5px; width: 100%; background: $brand-primary; opacity: 1; left: 0; transform: rotate(0deg); transition: .25s ease-in-out; }
.menu-icon span:nth-child(1) { top: 7px; }
.menu-icon span:nth-child(2),.menu-icon span:nth-child(3) { top: 19px; }
.menu-icon span:nth-child(4) { top: 31px; }
.menu-icon.open span:nth-child(1) { top: 18px; width: 0; left: 50%; }
.menu-icon.open span:nth-child(2) { transform: rotate(45deg); }
.menu-icon.open span:nth-child(3) { transform: rotate(-45deg); }
.menu-icon.open span:nth-child(4) { top: 18px; width: 0; left: 50%; }
.menu-icon:focus { outline: none; }


nav ul { margin: 0; padding: 0; }
nav ul li { list-style-type: none; margin: 0; padding: 0; }

@mixin font-size($sizeValue: 1.6) {
  font-size: ($sizeValue * 10) + px;
  font-size: $sizeValue + rem;
}

// RMA-Styles
@import "rma/_rma";

//Custom Styles
@import "connectiv/custom.scss";
@import "connectiv/lang-dropdown.scss";
@import "connectiv/karriereportal";