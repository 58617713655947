.rmatool {
  padding: 50px 0;
}

.rmatool label {
  display: block;
  font-weight: 700;
  margin-top: 20px;
}

.rmatool input {
  width: 100%;
  height: 34px;
  padding: 0 10px;
}

.rmatool input:disabled {
  background-color: #f6f6f6;
  border: 1px solid #aaa;
}

.tabwrapper {
  margin-top: 30px;
}

.steps > ul {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
}

.steps > ul li {
  border: 1px solid #0092D5;
  padding: 10px 20px;
  color: #0092D5;
  height: 100%;
}

.steps > ul li.current {
  background: #0092D5;
  color: #FFF;
}

.steps > ul li.current a,
.steps > ul li.current a:hover {
  color: #FFF;
}

.steps .current-info,
.content > .title
{
  position: absolute;
  left: -999em;
}

.content {
  padding: 30px 10px 30px 10px;
  border: 1px solid #0092D5;
  border-top: none;
}

.rmatool .btn {
  width: 100%;
  margin-top: 20px;
  height: 34px;
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
  padding: 8px 20px;
}

.rma-hint {
  position: relative;
  text-align: center;
  margin: 30px 0 0 0;
  color: #d70011;
  font-size: 12px;
}

.rmatool p {
  font-weight: 600;
}

.rmatool h4 {
  margin-bottom: 35px;
}

.rmatool label {
  font-weight: 700;
}

.rmatool select {
  width: 100%;
  height: 34px;
  padding: 0 10px;
  font-weight: bold;
}

.add-articlerow {
  background: none;
  border: none;
  color: #0092D5;
  margin-top: 20px;
  padding-left: 0;
}

.rma-marginbottom {
  margin-bottom: 15px;
}

.add-articlerow strong {
  display: inline-block;
  transform: scale(1.4);
  margin-right: 10px;
}

.content h3 {
  font-style: italic;
  font-weight: 600;
}

.rma-overviewtable {
  width: 100%;
  min-width: 658px;
}

.rma-overviewtable tr td {
  height: 50px;
  vertical-align: middle;
}

.rma-overviewtable tr:first-of-type th {
  height: 40px;
  font-weight: 700;
}

.rma-overviewtable tr {
  border-bottom: 1px solid #cfcfcf;
}

.rma-overviewtable tr:first-of-type {
  border-bottom: 1px solid #0092D5;
}

.rma-overviewtable tr:last-of-type {
  border-bottom: 0;
}

.rma-overviewtable {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
}

.content .overview-title {
  margin-bottom: 20px;
}

.content .overview-lettertitle {
  margin-bottom: 15px;
}

.content .overview-lettertitle p {
  font-weight: 400;
}

.content .overview-address {
  margin-bottom: 60px;
}

.content .overview-address p {
  font-weight: 400;
}

.content .overview-tabletitle {
  margin-bottom: 40px;
}

.content .rma-overviewtable {
  margin-bottom: 100px;
}

.content a.btn {
  height: 40px;
  padding: 10px 20px;
}

.rma-overviewtable th:last-of-type,
.rma-overviewtable td:last-of-type,
.rma-overviewtable td:nth-last-of-type(2){
  text-align: center;
  width: 46px;
}

.btn-delete, .btn-edit {
  display: block;
  width: 44px;
  height: 44px;
  text-align: center;
  margin: 0 auto;
}

.btn-delete img, .btn-edit img {
  height: 18px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
}

.rmatool .btn:hover {
  background: #0092D5;
}

.rmatool .btn:disabled {
  opacity: 0.5;
}

.btn.btn-red {
  background: #d70011;
  margin-top: 50px;
}

.btn.btn-red:hover {
  background: #d70011;
}

.btn.btn-red:after {
  background: #d70011!important;
}

.select-rma {
  margin: 20px 0 5px 0;
}

.rma-overview {
  padding: 0 15px 0 20px;
}

/* Kleine Geräte (Tablets, 768px und breiter) */
@media (min-width: 768px) {

  .content {
    padding: 40px 30px;
  }

  .rma-hint:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
    background: #eee;
  }

  .rma-hint span {
    position: relative;
    margin: 0 75px;
    padding: 0 20px;
    display: block;
    background: #FFF;
    z-index: 2;
  }

  .steps > ul {
    height: 60px;
    border: 1px solid #0092D5;
  }

  .steps > ul li  {
    position: relative;
    width: 25%;
    float: left;
    border: none;
  }

  .steps > ul li:not(:last-of-type) {
    border-right: 1px solid #0092D5;
  }

  .rmatool .btn {
    width: auto;
  }

  .rmatool label {
    font-size: 12px;
  }

  .steps > ul li.current + li.current {
    border-left: 1px solid #FFF;
  }

}

/* Mittlere Geräte (Desktop-PCs, 992px und breiter) */
@media (min-width: 992px) {

  .rmatool {
    padding: 40px 0;
  }

  .rmatool label {
    font-size: 14px;
  }

  .steps > ul {
    height: 40px;
  }

  .steps > ul li:not(:last-of-type):after {
    content: "";
    display: block;
    width: 1px;
    height: 100%;
    background: #0092d5;
    transform: skew(-30deg);
    position: absolute;
    right: -10px;
    top: 0;
  }

  .steps > ul li.current {
    border: none;
  }

  .steps > ul li.current:after {
    content: "";
    display: block;
    width: 22px;
    height: 100%;
    background: #0092d5;
    transform: skew(-30deg);
    position: absolute;
    right: -11px;
    top: 0;
  }

  .steps > ul li:not(:first-of-type) {
    padding-left: 35px;
  }

  .steps > ul li {
    border-right: none!important;
    height: 39px;
  }

  .steps > ul li.current + li.current {
    border-left: none;
  }

  .steps > ul li.current + li.current:before {
    content: "";
    display: block;
    width: 1px;
    height: 100%;
    background: #FFF;
    transform: skew(-30deg);
    position: absolute;
    left: 0;
    top: 0;
  }

  .rmatool .btn {
    margin-right: 20px;
    position: relative;
  }

  .rmatool .btn:after {
    content: "";
    display: block;
    width: 22px;
    height: 100%;
    background: #0092d5;
    transform: skew(-30deg);
    position: absolute;
    right: -11px;
    top: 0;
  }

}

/* Große Geräte (Desktop-PCs, 1200px und breiter) */
@media (min-width: 1200px) {

  .rmatool p,
  .rmatool label {
    font-size: 14px;
  }

}