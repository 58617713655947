/* source-sans-pro-300 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/source-sans-pro-v14-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/source-sans-pro-v14-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/source-sans-pro-v14-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/source-sans-pro-v14-latin-300.woff') format('woff'), /* Modern Browsers */
  url('../fonts/source-sans-pro-v14-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/source-sans-pro-v14-latin-300.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/source-sans-pro-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/source-sans-pro-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/source-sans-pro-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/source-sans-pro-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/source-sans-pro-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/source-sans-pro-v14-latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/source-sans-pro-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/source-sans-pro-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/source-sans-pro-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/source-sans-pro-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/source-sans-pro-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/source-sans-pro-v14-latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

/* source-sans-pro-600 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/source-sans-pro-v14-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/source-sans-pro-v14-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/source-sans-pro-v14-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/source-sans-pro-v14-latin-600.woff') format('woff'), /* Modern Browsers */
  url('../fonts/source-sans-pro-v14-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/source-sans-pro-v14-latin-600.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

/* source-sans-pro-700 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/source-sans-pro-v14-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/source-sans-pro-v14-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/source-sans-pro-v14-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/source-sans-pro-v14-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/source-sans-pro-v14-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/source-sans-pro-v14-latin-700.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}


/* ==========================================================================
   Project's custom styles
   ========================================================================== */

//@import 'https://fonts.googleapis.com/css?family=Ubuntu';
//@import 'https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i,700,700i';

$mobile-menu-height: 75px;

.container-fluid {
  max-width: 1000px + $grid-gutter-width;
}

*:focus {
  //outline-color: $brand-primary;
  outline: none !important;
}

html {
  scroll-behavior: smooth;
}

body {
  @include font-size(1.4);
  font-family: 'Source Sans Pro', sans-serif;
}

h1, h2, h3 {
  margin-top: 0;
  color: $brand-primary;
}

h1 {
  @include font-size(2.6);
  font-weight: 600;
}

h2 {
  @include font-size(2.2);
}

h3 {
  @include font-size(2);
}

h4 {
  @include font-size(1.8);
  margin-top: 0;
  font-weight: 500;

  span {
    //display: none;
  }
}

#content h4 {
  color: #2AA6DF !important;
}

p {
  @include font-size(1.6);
}

a {

}

body#tinymce {
  background-color: #FFF !important;
  box-shadow: none !important;
}

.con_randleiste {
  position: relative;
}

.con_randleiste p {
  margin: 0;
}

input, .form-control {
  border-radius: 0 !important;
  box-shadow: none !important;
}

header {
  position: relative;
  height: $mobile-menu-height;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
}

.fix-back {
  background-color: #fff;
  //box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
}

.logo img {
  width: 162px;
  height: auto;
  margin-top: 15px;
  margin-bottom: 10px;
}

.logo {
  position: relative;
}

.logoOuter.logo-nl {

  .logo {
    display: flex;
    align-items: flex-start;

    .logo__signalis {
      height: 50px;
      width: auto;
      margin: 1px 10px 5px 0;
    }

    .logo__haensch--nl {
      height: 20px;
      width: auto;
      margin: 22px 0 3px 0;
    }
  }
}

.menu-icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 2px 10px 0 0;
}

.menu-icon span {
  width: 32px;
  height: 3px !important;
  left: 6px;
}

.menu-icon span:nth-child(1) {
  top: 10px;
}

.menu-icon span:nth-child(4) {
  top: 28px;
}

.btn {
  line-height: 1.42857143; //fixes SASS number precision issue
  border-radius: 0;
  border: none;
  height: 40px;
  padding: 9px 30px;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-primary {
  //background-image: -moz-linear-gradient( 90deg, rgb(37,152,208) 0%, rgb(40,166,222) 100%);
  //background-image: -webkit-linear-gradient( 90deg, rgb(37,152,208) 0%, rgb(40,166,222) 100%);
  //background-image: -ms-linear-gradient( 90deg, rgb(37,152,208) 0%, rgb(40,166,222) 100%);
  background-color: #2AA6DF;
}

.btn--red {
  color: #fff;
  //background-image: -moz-linear-gradient( 90deg, rgb(204,33,18) 0%, rgb(223,36,19) 100%);
  //background-image: -webkit-linear-gradient( 90deg, rgb(204,33,18) 0%, rgb(223,36,19) 100%);
  //background-image: -ms-linear-gradient( 90deg, rgb(204,33,18) 0%, rgb(223,36,19) 100%);
  background: #D51818;
}

.btn--download,
.btn--mail,
.btn--inbasket {
  background: #D51818;
  color: #fff;
}

.btn--download,
.btn--mail {
  display: block;
  font-size: 18px;
  font-weight: 600;
  height: 50px;
  padding: 12px 30px;
}


.btn--inbasket span {
  display: block;
}

.btn--download span,
.btn--mail span {
  display: inline;
}

.btn--mail svg {
  position: relative;
  top: 2px;
  transform: scale(1.2);
  margin-right: 12px;
}

.btn--download svg {
  position: relative;
  top: 4px;
  margin-right: 10px;
}

.btn--download span small {
  font-style: normal;
}

.btn--download:hover,
.btn--mail:hover,
.btn--inbasket:hover {
  color: #fff;
}

.btn--download:focus,
.btn--download:active:focus,
.btn--mail:focus,
.btn--mail:active:focus,
.btn--inbasket:focus,
.btn--inbasket:active:focus {
  outline: none;
  color: #fff;
}

.btn--download:active,
.btn--download:hover,
.btn--mail:hover,
.btn--mail:active,
.btn--inbasket:hover,
.btn--inbasket:active {
  background: #D51818;
}

.btn--inbasket {
  margin-bottom: 30px;
}

.btn--skew {
  font-size: 18px;
  //width: 100%;
  padding: 12px 20px;
  height: 50px;
  font-weight: 600;
}

/*
.btn--download::before,
.btn--mail::before,
.btn--pdf::before {
  content: '';
  height: 100%;
  width: 55px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f2f2f2;
  background-position: center;
  background-repeat: no-repeat;
}

.btn--mail::after,
.btn--pdf::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 55px;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.btn--mail::after {
  background-image: url(../img/envelope-red.svg);
}

.btn--pdf::after {
  background-image: url(../img/document-file-pdf-red.svg);
}
*/
.container-outer.prod-cat {
  padding-top: 20px;
}

.search {
  position: relative;
}

.search form {
  margin: 10px;
}

.search input {
  width: 100%;
  height: 44px;
  border: none;
}

.search .searchterm {
  height: 50px;
  position: relative;
  width: 100%;
  border: none;
  padding-left: 10px;

  &:focus, &:hover, &:active {
    outline: none !important;
  }
}

.search .searchterm:focus {
  outline-color: transparent;
}

.search--btn {
  position: absolute;
  top: 2px;
  right: 12px;
  padding: 0;
  height: 46px;
  width: 50px;
  border: 0;
  background-image: -moz-linear-gradient(90deg, rgb(37, 152, 208) 0%, rgb(40, 166, 222) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(37, 152, 208) 0%, rgb(40, 166, 222) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(37, 152, 208) 0%, rgb(40, 166, 222) 100%);
  cursor: pointer;
  z-index: 1;
  text-align: center;
}

.search--btn img {
  height: 24px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.search--btn .schliessen {
  display: none;
}

.slidein-search button {
  display: none;
}

.nav-top {
  height: 100%;
}

.nav-top__shop-item > span {
  display: inline-block;
  color: #fff;
  padding: 10px 10px;
}

.nav0-1 {
  display: none;
  position: absolute;
  background-image: -moz-linear-gradient(90deg, rgb(37, 152, 208) 0%, rgb(40, 166, 222) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(37, 152, 208) 0%, rgb(40, 166, 222) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(37, 152, 208) 0%, rgb(40, 166, 222) 100%);
  left: 0;
  right: 0;
  z-index: 200;
  width: 100%;
  padding: 10px 0;
}

.nav0-1 a {
  position: relative;
  @include font-size(2);
  display: inline-block;
  color: #FFF;
  padding: 10px 10px;
}

.nav0-1 > ul {
  position: relative;
  height: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav0-1 ul li {
  position: relative;
}

nav ul li > div {
  display: none;
}

nav ul li > div.active {
  display: block;
}

.nav-top .nav0-2 ul {
  padding-left: 10px;
}

.nav-top .nav0-2 a {
  @include font-size(1.8);
  padding: 5px 5px 5px 10px;
}

.nav-top .nav0-3 a {
  @include font-size(1.6);
}

#content #et-opt-out {
  background-color: #007bb4;
}

#content #et-opt-out.et-disabled {
  background-color: #ccc;
}

.dropdown-icon {
  position: absolute;
  display: block;
  width: 44px;
  height: 43px;
  right: 10px;
  top: 0;
  transition: all 0.3s;
  background-color: #007bb4;
  text-align: center;
  cursor: pointer;
}

.nav-top .nav0-2 li {
  margin-bottom: 2px;
}

.nav-top .nav0-2 .dropdown-icon {
  right: 0;
  height: 32px;
}

.nav-top .nav0-3, .nav-top .nav0-4, .nav-top .nav0-5 {
  display: none;
}

.nav0-3.active, .nav0-4.active, .nav0-5.active {
  display: block;
}

.nav-top .nav0-3 .dropdown-icon {
  top: 2px;
  right: 0;
  height: 27px;
}

.dropdown-icon.rotate {
  transform: scaleY(-1);
}

.dropdown-icon img {
  fill: #FFF;
  transition: transform 0.3s;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#content {
  padding-top: 20px;
  padding-bottom: 25px;
  hyphens: auto;

  .list-group-item {
    display: block;
  }
}

body.start #content img {
  border: none;
}

#content img {
  margin-bottom: 20px;
  //border: 1px solid #dbdfe8;
  max-width: 100%;
}

.footer-top {
  //padding-top: 50px;
}

.cert {
  margin: 0 0 50px 0 !important;
}

@media (max-width: 767px) {
  .prod-cat {
    padding-bottom: 0px !important;
  }
}

#content .search__articles {

  .img-outer {
    margin-bottom: 10px;
  }

  h2 {
    font-size: 18px;
    font-weight: 400;
    height: auto;
  }
}

.prodlist-item-wrapper {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);

  .btn {
    width: 100%;
  }
}

.prodlist-image {
  margin-bottom: 20px;
  //box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;

  img {
    margin-bottom: 0 !important;
    border: 0 !important;
    margin: 0 auto;
    width: auto;
  }
}
.product__btn a {
  color: #FFFFFF !important;
  &:hover {
    color: #FFFFFF !important;
  }
}

.cert-slider .slick-track {
  display: flex;
  align-items: center;
}

.cert-item img {
  margin: 0 auto;
  height: auto!important;
}

.reg-nl input {
  font-size: 18px;
}

.product__btn a {
  color: #FFFFFF !important;

  &:hover {
    color: #FFFFFF !important;
  }
}

.cert-slider .slick-track {
  display: flex;
  align-items: center;
}

.cert-item img {
  margin: 0 auto;
  height: auto !important;
}

.reg-nl input {
  font-size: 18px;
}

#content .slider img {
  margin-bottom: 0;
}

.content h2,
#content h2 {
  color: #2AA6DF !important;
  @include font-size(2.2);
  font-weight: 600;
  //margin-bottom: 20px;
}

.content h3,
#content h3 {
  margin-top: 0;
  color: $brand-primary;;
  @include font-size(1.4);
  font-weight: 700;
  margin-bottom: 15px;
}

#content hr {
  border-color: $brand-primary;
  margin-top: 13px;
  margin-bottom: 15px;
}

#content li {
  //font-style: italic;
  padding-bottom: 5px;
  line-height: 1.5;
  @include font-size(1.6);
}

#content li::before {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: middle;
  border-left: $caret-width-base solid $brand-red;
  border-left: $caret-width-base solid \9
; // IE8
  border-top: $caret-width-base solid transparent;
  border-bottom: $caret-width-base solid transparent;
  background-color: inherit;
}

#content .slick-dots li {
  padding: 0;
}

#content .slick-dots li::before {
  content: none;
}

.list {
  font-weight: 600;
}

.hänsch_divider {
  color: $brand-red;
  padding: 0 3px;
}

.hänsch_divider::before,
.hänsch_divider::after {
  content: "/";
  color: $brand-primary;
}

footer {
  padding-top: 55px;
  color: #fff;
  background-color: #2AA6DF;
  line-height: 1.45;
  background-size: cover;
  background-position: center;
}

footer a {
  color: #fff;
}

footer a:hover {
  color: #fff;
}

footer ul {
  list-style-type: none;
  padding-left: 0;
}

footer ul li {
  line-height: 1.2;
  margin-bottom: 8px;
}

footer ul .head {
  text-transform: uppercase;
  margin-bottom: 13px !important;
}

footer .newsletter {
  margin-bottom: 30px;
  font-style: italic;
}

footer .newsletter_head {
  display: block;
  margin-bottom: 5px;
}

footer .newsletter_input {
  position: relative;
  padding-top: 5px;
  max-width: 320px;
}

footer .newsletter_input input {
  height: 35px;
  width: 100%;
  padding: 0 60px 0 10px;
  border: none;
  color: #000;
}

footer .newsletter button {
  height: 35px;
  width: 50px;
  background-image: url("../img/pencil.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 17px;
  background-color: $brand-primary;
  position: absolute;
  right: 0;
  top: 5px;
  border: none;
  color: #fff;
  padding: 0 10px;
}

footer .social-media {
  margin-bottom: 5px;
}

footer .social-media img {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  margin-left: 20px;
  margin-top: -3px;
}

footer .social-media a:first-of-type img {
  margin-left: 0;
}

footer .address img {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.address li {
  margin-bottom: 8px;
}

.contact__menu .oeffnungszeiten {
  font-weight: 300;
}

//.cert {
//  margin-top: 20px;
//  margin-bottom: 65px;
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  flex-wrap: wrap;
//}
//
//.cert_img {
//  margin: 15px 25px;
//}
//
//.cert_img img {
//  height: 50px;
//}

.cert {
  text-align: justify;
  //margin-top: 20px;
  //margin-bottom: 55px;
  word-wrap: break-word;
}

.cert_img {
  margin: 15px 10px 0;
  display: inline-block;
}

.cert_img img {
  height: 50px;
  width: auto;
}

.prodlist-item > a {
  display: block;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);;
}

.slider__item {
  //background: $brand-red;
  position: relative;
}

.mobile-header {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
}

.jumbo-img {
  width: 100%;
  height: 135px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

body:not(.start) .jumbo-img {
  max-width: 1920px;
}

.jumbo-section {
  background-color: #2AA6DF;
}

.jumbo-section h2 {
  color: #FFFFFF !important;
  text-align: center;
  margin-bottom: 15px;
  font-size: 28px;
  font-weight: 600;
}

.jumbo-section h4 {
  color: #FFFFFF !important;
  margin-bottom: 20px;
  font-size: 16px;
}

.jumbo-section {
  text-align: center;
  padding: 30px 20px;
}

.jumbo-section__btn {
  width: 100%;
  height: 50px;
  background-color: #FFFFFF;
  margin: auto;
  line-height: 13px;
  font-size: 18px;
  color: #2AA6DF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  font-weight: 600;
}

.start-slider .slick-arrow {
  top: 47%;
  color: #FFFFFF;
  opacity: 1 !important;

  &:hover {
    cursor: pointer;
  }
}

.start-slider .slick-next {
  right: 10px;
}

.start-slider .slick-prev {
  left: 10px;
}

.start-slider .slick-arrow .a {
  stroke: #FFFFFF;
  opacity: 1;
}

.slick-dots {
  display: none !important;
}

.slider.prod-slider .slick-arrow {
  opacity: 1;
  transform: translateY(-50%) scale(1.25);
  filter: drop-shadow(0 0 5px #000);
}

.start-slider .jumbo-img {
  height: 220px;
}

@media (max-width: 767px) {
  .start-slider .slick-arrow {
    top: 24%
  }
}

.start-slider .canvas-wrap h2,
.start-slider .canvas-wrap h3,
.start-slider .canvas-wrap h4 {
  color: #FFF !important;
}

.slider__item .slider-box {
  display: block;
  position: relative;
  padding: 18px 20px;
  z-index: 10;
}

.slider__item .slider-box:focus {
  outline: none;
}

.slider__item .slider-box h2,
.slider__item .slider-box h3,
.slider__item .slider-box h4 {
  color: #fff;
}

.slider__item .slider-box h2 {
  margin-bottom: 2px;
}

.slider__item .slider-box h4 {
  margin-bottom: 15px;
}
.start-slider .slick-arrow {
  background-image: none;
  width: 16px;
  height: auto;
  filter: drop-shadow(0px 0px 5px #000);
}
.content-slider .slick-arrow {
  background-image: none;
}
.content-slider--f .slick-arrow {
  background-image: none;
  width: 16px;
  height: auto;
}
.content-slider--prod .slick-arrow , .content-slider .slick-arrow  {
  background-image: none;
  width: 16px;
  height: auto;
}

.start-slider .slick-arrow {
  background-image: none;
  width: 16px;
  height: auto;
  filter: drop-shadow(0px 0px 5px #000);
}

.content-slider .slick-arrow {
  background-image: none;
}

.content-slider--f .slick-arrow {
  background-image: none;
  width: 16px;
  height: auto;
  opacity: 1;
}

.content-slider--f .slick-arrow path {
  stroke: #FFF;
}

.content-slider--prod .slick-arrow, .content-slider .slick-arrow {
  background-image: none;
  width: 16px;
  height: auto;
}

.slider__item .slider-box .icon-angle-right {
  display: inline-block;
  position: absolute;
  background: url(../img/angle-right-white.svg) no-repeat center;
  background-size: 5px;
  height: 19px;
  width: 1em;
}
.slick-slide {
  height: auto ;
}

.content-section {
  margin: 50px 30px;
}
.box-distinguishes-section {
  margin: 50px 30px;
}

.slick-slide {
  height: auto;
}

.content-section {
  margin: 50px 30px;
}

.box-distinguishes-section {
  margin: 20px 30px 0 30px;
}

.content-slider--prod {
  margin-top: 10px;

  .btn {
    width: 100%;

    svg {
      height: 16px;
      margin-left: 10px;
      position: relative;
      top: 2px;
    }
  }
}

.prod__title {
  z-index: 1;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
}

.content-slider__item {
  position: relative;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
}

.content-slider--prod .slick-next, .content-slider .slick-next, .content-slider--t .slick-next {
  right: -17px;
  top: 43%;
  width: 10px;
  height: 21px;
}

.content-slider--prod .slick-prev, .content-slider .slick-prev, .content-slider--t .slick-prev {
  left: -13px;
  top: 43%;
  width: 10px;
  height: 21px;
}

.content-slider__item {
  position: relative;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
}
.content-slider--prod .slick-next , .content-slider .slick-next ,.content-slider--t .slick-next {
  right: -17px;
  top: 43%;
  width: 10px;
  height: 21px;
}
.content-slider--prod .slick-prev , .content-slider .slick-prev , .content-slider--t .slick-prev {
  left: -13px;
  top: 43%;
  width: 10px;
  height: 21px;
}
.content-slider--prod .content-slider__item .prod-img,
.product-cat-list .content-slider__item .prod-img {
  display: block;
  position: relative;
  height: 170px;
  background-color: #fff;
}

.content-slider--prod .content-slider__item img,
.product-cat-list .content-slider__item img {
  position: absolute;
  -webkit-transform: translate(50%, -50%);
  -ms-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  top: 50%;
  right: 50%;
  padding: 20px 10px;
  max-height: 100%;
  width: auto;
}

.content-slider--prod.content-slider--signalis .content-slider__item img {
  padding: 0;
}

.content-slider--prod .content-slider__item span,
.product-cat-list .content-slider__item span {
  display: block;
  color: #000;
  padding: 20px 20px 0 20px;
  font-size: 20px;
}

.content-slider ul.slick-dots {
  margin-top: 25px;
}

.box {
  padding: 45px 0 0;
}

.box--blue {
  background: url('../img/pattern_blue.gif');
  box-shadow: inset 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 0;
}

.box--blue h1 {
  color: #fff;
}
.box-section h1 {
  margin: 10px 30px;
}
.box__btn {
  background-color:#2aa6df;
  color: #FFFFFF ;
  border: none;
  width: 100%;
  height: 50px;
  margin: auto;
  line-height: 13px;
  font-size: 18px;
  font-weight: 600;
}

p:empty {
  margin: 0!important;
  height: 0!important;
  display: none!important;
}

.box-section h1 {
  margin: 10px 30px;
}

.box__btn {
  background-color: #2aa6df;
  color: #FFFFFF;
  border: none;
  width: 100%;
  height: 50px;
  margin: auto;
  line-height: 13px;
  font-size: 18px;
  font-weight: 600;
}

p:empty {
  margin: 0 !important;
  height: 0 !important;
  display: none !important;
}

.content-slider--f .content-slider__item {
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #fff;
  color: #000;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.3s;
}

.content-slider--f .content-slider__item--image {
  display: block;
  height: 185px;
  overflow: hidden;

  img {
    height: 185px;
    width: auto;
    max-width: inherit !important;
    position: relative;
    transform: translateX(-50%);
    left: 50%;
    margin: 0 !important;
  }
}


@media (hover: hover) {

  .content-slider--prod .content-slider__item {
    transition: box-shadow 0.3s;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .content-slider--prod .content-slider__item:hover {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.6);
  }
}

.content-slider--f .content-slider__item:hover {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.8);
}

.content-slider--f img {
  width: 100%;
}

.content-slider--f .content-slider__item .content-slider__item--text {
  display: block;
  padding: 22px;
  min-height: 245px;
  word-wrap: break-word;
  font-size: 16px;
}

.content-slider--f .content-slider__item strong {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
}

.content-slider--f .slick-dots li button {
  background-color: lighten($brand-primary, 25%);
}

.content-slider--f .slick-dots li.slick-active button {
  background-color: #fff;
}

.content-slider--t .content-slider__item,
.nav-left-fixer .teaser {
  margin-top: 5px;
  margin-bottom: 5px;
  color: #000;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  height: 160px;
  position: relative;
  padding: 12px 20px 50px 20px;
  overflow: hidden;
}

.content-slider--t .content-slider__item span,
.nav-left-fixer .teaser span {
  display: block;
}

/*
.content-slider--t .content-slider__item span:first-of-type,
.nav-left-fixer .teaser span:first-of-type {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 400;
}

.nav-left-fixer .teaser span:first-of-type {
  font-size: 14px;
  max-width: 65%;
}

.content-slider--t .content-slider__item span:last-of-type,
.nav-left-fixer .teaser span:last-of-type {
  width: 50%;
  top: 105px;
}
.content-slider--t .content-slider__item span {
  margin-left: -20px;
}
.nav-left-fixer .teaser span:last-of-type {
  top: 96px;
  width: 55%;
}
*/
.content-slider--t img,
.nav-left-fixer .teaser img {
  position: absolute;
  top: 50%;
  right: 10px;
  padding: 10px;
  max-height: 100%;
  transform: translate(0%, -50%);
  vertical-align: bottom;
}

.content-slider--t img {
  margin-bottom: 60px !important;
}

.nav-left-fixer .teaser img {
  max-width: 42%;
  right: 0;
}

.prod-slider {
  margin-bottom: 40px;
}

#content .prod-slider > ul.slick-dots {
  margin-top: 10px;
}

.prod-slider__item img {
  border: 1px solid #dbdfe8;
  width: 100%;
}

.ansprechpartner table {
  @include font-size(1.3);
}

.ansprechpartner-item {
  margin-bottom: 15px;
}

#content p, #tinymce p {
  margin-bottom: 20px;
}

.payment__head {
  @include font-size(1.5);
  font-style: italic;
  font-weight: 600;
  color: $brand-primary;
  margin-bottom: 20px;
  display: block;
}

.payment {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.payment__item {
  height: 44px;
  border-bottom: 1px solid #2AA6DF;
  border-radius: 3px;
  text-align: center;
  position: relative;
  width: 31%;
  background: #f7f7f5;
  padding: 0 3px;
}

.payment__item * {
  font-size: 11px;
  font-style: italic;
  position: relative;
  display: block;
  top: 50%;
  transform: translateY(-50%);
}

.payment__item img {
  max-width: 100%;
}

.prod-detail {
  margin-bottom: 30px;
}

.prod-detail_image img {
  border: 1px solid #ccc;
}

.prod-detail__ab {
  display: block;
  @include font-size(2);
  font-weight: 600;
  font-style: italic;
  margin-top: 25px;
}

.prod-detail__price {
  color: $brand-red;
  @include font-size(3.2);
  font-style: italic;
  line-height: 1;
}

.prod-detail__vat-info {
  display: inline-block;
  @include font-size(1.2);
  line-height: 1.3;
  padding: 9px 0px 15px 20px;
}

.prod-detail__zus {
  color: #000;
  font-style: italic;
  line-height: 22px;
}

.prod-var-list {
  margin-top: 25px;
  margin-bottom: 60px;
}

.prod-var-list table {
  width: 100%;
}

.prod-var-list table th,
.prod-var-list table td {
  padding: 0 8px;
  box-sizing: content-box;
}

.prod-var-list table th:first-child {
  padding-left: 0;
  width: 70px;
}

.prod-var-list table th:nth-child(3) {
  width: 15%;
}

.prod-var-list table th:nth-child(4) {
  width: 16%;
}

.prod-var-list table th:last-child {
  width: 50px;
}

.prod-var-list table th:last-child,
.prod-var-list table td:last-child {
  padding-right: 0;
}

.prod-var-list table img {
  float: left;
  margin: 0 !important;
  border: none !important;
}

.prod-var-list.visible-xs.visible-sm img {
  float: left;
  margin: 0 15px 0 0 !important;
  border: none !important;
}

.prod-var-list .btn--basket {
  padding: 9px 15px;
  font-size: 20px;
  float: right;
}

.prod-var-list__head,
.prod-var-list table th {
  font-style: italic;
  font-weight: 600;
  vertical-align: top;
  line-height: 1.3;
}

.prod-var-list__artnum {
  line-height: 1.6;
  vertical-align: bottom;
}

.prod-var-list__item .spacer {
  height: 10px;
}

.prod-var-list input {
  padding: 9px 11px;
  border-width: 1px;
  border-style: ridge;
  text-align: center;
}

.prod-var-list__price {
  color: $brand-red;
  font-weight: 600;
  font-style: italic;
  line-height: 40px;
}

.prod-var-list__item .row + .spacer + .row .prod-var-list__head {
  line-height: 40px;
}

[class^="icon-"], [class*=" icon-"] {
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
  height: 1em;
  width: 1em;
}

.icon-shopping-cart {
  background-image: url(../img/shopping-cart.svg);
}

.icon-shopping-cart-black {
  background-image: url(../img/shopping-cart-black.svg);
}

.icon-redo {
  background-image: url(../img/redo.svg);
}

.icon-star {
  background-image: url(../img/star.svg);
}

.icon-user {
  background-image: url(../img/user.svg);
}

.icon-user-black {
  background-image: url(../img/user-black.svg);
}

.icon-phone {
  background-image: url(../img/phone.svg);
}

.icon-pencil {
  background-image: url(../img/pencil.svg);
}

.shop-list__item {
  margin-bottom: 50px;
}

.shop-list__item a:first-child {
  margin-bottom: 20px;
  display: block;
  height: 235px;
  border: 1px solid #dbdfe8;
  background: #fff;
}

.shop-list__item img {
  margin-bottom: 0 !important;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  border: none !important;
  margin: 0 auto;
  display: block;
}

hr.grey {
  border-color: #eee !important;
}

.alt-prod__item img {
  margin: 0 0 13px 0 !important;
}

.alt-prod__title {
  display: block;
  color: #000;
  font-style: italic;
  font-weight: 600;
}

.alt-prod__artnum {
  display: block;
  color: #000;
  @include font-size(1.2);
  line-height: 2.2;
  font-style: italic;
}

.alt-prod__price {
  display: block;
  margin-top: 5px;
  margin-bottom: 15px;
  color: $brand-red;
  @include font-size(1.6);
  font-weight: 600;
  font-style: italic;
}

.global--shop ul {
  padding-left: 0;
}

.global--shop li {
  margin-right: 5px;
}

.global--shop li:last-of-type {
  margin-right: 0;
}

.global--shop a {
  color: #000;
}

.global--shop a.title {
  font-weight: 700;
}

.content-slider--t .content-slider__item {
  height: 265px;
}

/*
.content-slider--t .content-slider__item span:last-of-type {
  width: 100%;
  top: 25px;
}
*/
.content-slider--t .content-slider__item span {
  display: block;
  width: 100%;
  max-width: 100%;
  font-size: 20px;
}

.content-slider--t img {
  left: 0;
  right: 0;
  bottom: 0;
  height: 133px !important;
  width: auto;
  transform: unset;
  margin: auto;
}

.content-slider--t span {
  text-align: center;
}

.box-distinguishes__btn {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.data-section {
  background-color: #F5F5F5;
  padding: 20px 0;
  text-align: center;
  height: 120px;
}

.data-section span {
  font-size: 18px;
  color: #2AA6DF;
}

.data-section ul {
  display: flex;
  justify-content: center;
  order: 1;
  margin-bottom: 25px;
}

.data-section ul li {
  padding: 0 10px;
  margin-bottom: 0;
  line-height: 1;
}

.data-section ul li:nth-child(2) {
  border-left: 1px solid #2AA6DF;
  border-right: 1px solid #2AA6DF;
}

.data-section ul li a {
  color: #2AA6DF !important;
  font-size: 18px;
}

.show-more__list {
  cursor: pointer;
}

.hidden-list {
  display: none;
}

footer .contact__menu {
  font-size: 18px;
  font-weight: 600;
  margin: 0 20px 50px 20px;
}

footer .address.contact__menu li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 17px;
}

footer .address.contact__menu li.head {
  padding-left: 0;
  margin-top: 10px;
  margin-bottom: 17px !important;
}

footer img.address {
  position: absolute;
  left: 0;
  top: 3px;
}

footer .address.address-icon-phone {
  width: 18px;
  height: 18.04px;
}

footer .address.address-icon-mail {
  width: 18.65px;
  height: 14.66px;
}

footer .accordion-list .accordion-list__item {
  margin-bottom: 15px;
}

footer .accordion-list .accordion-list__item:last-of-type {
  margin-bottom: 30px;
}

.position-relative {
  position: relative;
}

.product-breadcrumb .breadcrumb {
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 12px;
  padding-left: 10px;
}

.breadcrumb li a {
  color: #000000;
}

.breadcrumb > li:last-of-type a {
  color: #2AA6DF;
}

.prodcat-2cols .btn.btn-primary {
  display: block;
  font-size: 18px;
  font-weight: 600;
  height: 50px;
  padding: 11px 30px;
  margin: 0 !important;

  &:hover, &:active, &:focus {
    background: #2AA6DF;
  }
}

.prodcat-2cols {
  display: block;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  transition: box-shadow 0.3s;

  > a:first-of-type {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
  }

  img {
    width: 100%;
    margin: 0 !important;
  }

  h2 {
    display: none;
    padding: 0 22px 10px 22px;
    min-height: 70px;
  }

  p:empty {
    display: none;
  }

  &:hover {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.8);
  }
}

.social-item span {
  font-style: normal !important;
}

div[class^="diashow"] img {
  object-fit: cover;
}

.aktuelles-startseite {
  background-color: $brand-primary;

  h1 {
    color: #FFF;
  }

  .content-slider__item {
    background-color: #F5F5F5;
  }

  .box__btn {
    background-color: #FFF;
    color: $brand-primary;
    box-shadow: 0 2px 5px 0 #DEDEDE inset;
  }

  .content-slider__item--image {
    img {
      height: 100% !important;
      object-fit: cover;
    }
  }
}

.sticky-icons .close-sticky-icons {
  padding: 0;
}

.sticky-icons .close-sticky-icons .si-open {
  line-height: 20px;
}

.sticky-icons .close-sticky-icons .si-close {
  line-height: 20px;
}

@media (max-width: 539px) {
  .content-slider--f .content-slider__item .content-slider__item--text {
    min-height: 105px;
  }
}

@media (max-width: 767px) {

  .content-slider--f {
    margin: 0 20px 50px;
  }
  .menu-icon span {
    height: 4px;
  }

  .content-slider--f .content-slider__item .content-slider__item--text {
    min-height: 135px;
  }

  .content-slider--f .content-slider__item--image img {
    height: auto;
    width: 100.5%;
  }
  .cert {
    //margin: 80px 0 0;
  }
  footer .menue {
    margin: 10px 20px !important;
  }
  footer .head {
    text-transform: uppercase;
    margin-bottom: 13px !important;
    display: block;
    font-size: 20px;
    position: relative;
    font-weight: 600;
  }
  .accordion__inc {
    position: absolute;
    right: 0;
    z-index: 99999;
    width: 44px;
    height: 44px;
    text-align: center;
    padding: 10px;
    top: -10px;
    font-weight: 400;
  }
  .accordion__dec {
    position: absolute;
    right: 0;
    display: none;
    z-index: 99999;
    width: 44px;
    height: 44px;
    text-align: center;
    padding: 10px;
    top: -10px;
    font-weight: 400;
  }
  .accordion-list {
    border-bottom: 2px solid #ffffff;
  }
  .accordion-list .accordion-list__item {
    display: none;
  }
  footer .address {
    //margin-top: 10px !important;
  }
  .show-more__list {
    display: none !important;
  }
  .logoOuter {
    padding: 9px 0;
  }
  .container-outer {
    padding: 0 20px;
  }
  footer .accordion-list {
    margin-bottom: 20px;
  }
  footer .accordion-list__item a {
    font-size: 18px;
  }
}

.prodlist-image {
  img {
    height: 200px;
    object-fit: contain;
  }
}

.prodlist-image {
  img {
    height: 200px;
    object-fit: contain;
  }
}
/* Kleine Geräte (Tablets, 768px und breiter) */
@media (min-width: $screen-tablet) {

  .start-slider .slick-dots {
    display: none !important;
    //margin-top: -30px!important;
  }
  /*
    .start-slider:after {
      content: "";
      display: block;
      width: 80px;
      height: 40px;
      background-color: $brand-primary;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23fff' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 20px 0;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -40px;
      animation: loopBgArrow 1.5s linear infinite;
    }
  */

  @keyframes loopArrow {
    0% {
      margin-top: 0px;
    }
    33.33333% {
      margin-top: -2px;
    }
    66.66666% {
      margin-top: 2px;
    }
    100% {
      margin-top: 0px;
    }
  }


  .content-slider--f .slick-track {
    display: flex;
  }

  .content-slider--f .box__btn {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .content-slider--f .content-slider__item {
    padding-bottom: 50px;
  }

  .box {
    padding: 20px 0;
  }

  .content-slider--prod {
    margin-bottom: 20px;
  }

  body {
    //background-color: #f3f3f3;
    //box-shadow: inset 0px 0px 150px 150px #cecece;
  }

  body.start {
    background-color: #fff;
    box-shadow: none;
  }

  body.start #content {
    padding-bottom: 0;
  }

  .btn--skew {
    font-size: 16px;
    padding: 12px 20px;
  }

  .height-100 {
    height: 100%;
  }

  .prodlist-image {
    img {
      height: 150px;
    }
  }

  header {
    height: 125px;
    //position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 999;
    //transition: height 0.5s;
    //-webkit-transition: height 0.5s;
    //-moz-transition: height 0.5s;

    //.logo {
    //  transition: all 0.5s;
    //  -webkit-transition: all 0.5s;
    //  -moz-transition: all 0.5s;
    //}
    //
    //.nav-top {
    //  transition: all 0.5s;
    //  -webkit-transition: all 0.5s;
    //  -moz-transition: all 0.5s;
    //}
  }

  //header.fixed {
  //  position: fixed;
  //  top: 0;
  //  right: 0;
  //  left: 0;
  //  height: 60px;
  //  transition: height 0.5s;
  //  -webkit-transition: height 0.5s;
  //  -moz-transition: height 0.5s;
  //
  //  .logo {
  //    padding: 10px 0 0px;
  //    margin-top: 0;
  //    transition: all 0.5s;
  //    -webkit-transition: all 0.5s;
  //    -moz-transition: all 0.5s;
  //  }
  //
  //  .nav-top {
  //    margin-top: 0;
  //    transition: all 0.5s;
  //    -webkit-transition: all 0.5s;
  //    -moz-transition: all 0.5s;
  //  }
  //}

  //header + .space {
  //  height: 105px;
  //}

  .fix-back {
    right: 0;
    left: 0;
  }

  .fix-back.desktop-fixed {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);

    .logoOuter {
      max-height: 80px;
      overflow: hidden;
    }

    .nav-top {
      height: 80px;
    }
  }

  .global-lang {
    background-color: #2AA6DF;
    height: 35px;
  }

  .logoOuter {
    height: auto;
  }

  .logo {
    display: block;
    float: left;
    padding: 20px 0;
    margin: 10px 0 0 0;
  }

  .logo img {
    width: 162px;
    max-width: 237px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .logoOuter.logo-nl {

    .logo {
      align-items: baseline;
      display: block;
      padding-bottom: 55px;
      width: 100%;

      &.desktop-fixed {
        top: 18px !important;
      }

      .logo__signalis {
        position: absolute;
        margin: 0;
        height: auto;
        width: 80px;
        left: 95px;
        bottom: 15px;
      }

      .logo__haensch--nl {
        position: absolute;
        margin: 0;
        height: auto;
        width: 90px;
        left: 0;
        bottom: 24px;
      }
    }

    .desktop-fixed .logo__signalis {
      bottom: 10px;
    }

    .desktop-fixed .logo__haensch--nl {
      bottom: 18px;
    }
  }

  .nav-top {
    height: 90px;
    //background-color: $brand-primary;
    //margin-top: 45px;
    position: relative;
  }

  .nav0-1 {
    display: inline !important;
    //position: relative;
    //top: inherit;
    //left: inherit;
    right: 0;
    padding: 15px 0 0 0;
    height: 100%;
    width: auto;
    background-color: transparent;
    background-image: none;
    left: -4px;
    //margin-right: 60px;
  }
  /*
    .nav0-1:before {
      content: "";
      width: 18px;
      height: 30px;
      background-color: #FFF;
      //background-image: -moz-linear-gradient( 90deg, rgb(37,152,208) 0%, rgb(40,166,222) 100%);
      //background-image: -webkit-linear-gradient( 90deg, rgb(37,152,208) 0%, rgb(40,166,222) 100%);
      //background-image: -ms-linear-gradient( 90deg, rgb(37,152,208) 0%, rgb(40,166,222) 100%);
      transform: skewX(-30deg);
      position: absolute;
      left: -9px;
      top: 0;
      z-index: 1;
    }
  */


  .nav-top .nav0-1 a {
    @include font-size(1.4);
    padding: 20px 10px 10px 10px;
    height: 100%;
    z-index: 2;
    transition: 700ms color;
    color: #4B4B4B;

    &:hover {
      color: #2AA6DF;
    }
  }
  .nav-top .megadropdown a {
    color: #FFFFFF;
  }

  .nav-top .nav0-1 a span.caret {
    border-top: $caret-width-large dashed;
    border-top: $caret-width-large solid;
    border-right: $caret-width-large solid transparent;
    border-left: $caret-width-large solid transparent;
    display: none;
  }


  /*
    .nav-top .nav0-1 > ul > li > a.active.sub:after {
      content: '';
      border-top: 6px solid $brand-primary;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      position: absolute;
      bottom: -6px;
      right: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
    }

    */

  .nav0-1 > ul {
    display: flex;
    justify-content: space-between;
    position: static;
    width: 364px;
    padding-right: 37px;
  }

  .nav-top .nav0-1 > ul {
    padding-left: 0;
    color: #4B4B4B;
    //-ms-transform: skewX(-30deg);
    //-webkit-transform: skewX(-30deg);
    //transform: skewX(-30deg);
  }

  .nav0-1 ul li {
    position: static;
  }

  .nav0-1 > ul > li {
    text-align: center;
    transition: all 0.3s;
  }

  .nav-top .nav0-1 > ul > li {
    height: 100%;
    //width: 100%;
    white-space: nowrap;
  }

  .nav-top .nav0-1 > ul > li:first-of-type {
    //margin-left: 10px;
  }

  .nav-top .nav0-1 > ul > li:nth-last-of-type(2) {
    //margin-right: 5px;
  }

  .nav0-1 > ul > li.search:hover {
    background-color: darken($brand-red, 5%);
  }

  .nav-left .nav0-3, .nav-left .nav0-4, .nav-left .nav0-5 {
    display: block;
  }

  .search {
    position: absolute;
    top: 18px;
    right: 0;
  }


  .search form {
    margin: 0;
    height: 60px;
    position: relative;
  }

  .search input {
    //border: 1px solid $brand-primary;
    //height: 32px;
  }

  .search .slidein-search {
    width: 177px;
    border: none;
  }

  .search .search--btn.open + .slidein-search {
    height: 50px;
  }
  .search--btn {
    display: none !important;
  }

  .slidein-search button {
    display: block;
    position: absolute;
    right: -27px;
    top: 4px;
    height: 51px;
    width: 60px;
    background-color: transparent;
    border: none;
  }

  .search .search--btn.open .lupe {
    display: none;
  }

  .search .search--btn.open .schliessen {
    display: block;
  }
  .search .searchterm {
    border-bottom: 1px solid #ABABAB;
    padding-top: 15px;
    height: 44px;
  }

  .search--btn {
    position: relative;
    width: auto;
    height: 100%;
    outline: none;
    background-color: $brand-red;
    background-image: -moz-linear-gradient(90deg, rgb(204, 33, 18) 0%, rgb(223, 36, 19) 100%);
    background-image: -webkit-linear-gradient(90deg, rgb(204, 33, 18) 0%, rgb(223, 36, 19) 100%);
    background-image: -ms-linear-gradient(90deg, rgb(204, 33, 18) 0%, rgb(223, 36, 19) 100%);
    top: 0;
    right: 0;
  }

  .search--btn img {
    height: 16px;
    z-index: 100;
    //-ms-transform: skewX(30deg);
    //-webkit-transform: skewX(30deg);
    //transform: skewX(30deg);
    position: absolute;
    top: 30px;
    left: 12px;
  }

  .search--btn > .triangle {
    //-ms-transform: skewX(30deg);
    //-webkit-transform: skewX(30deg);
    //transform: skewX(30deg);
    position: absolute;
    top: 0;
    right: 0;
    width: 41px;
    height: 100%;
    z-index: -1;
  }

  .nav-top .megadropdown {
    //-ms-transform: skewX(30deg);
    //-webkit-transform: skewX(30deg);
    //transform: skewX(30deg);
    transform-origin: top right;
    position: absolute;
    padding: 0 10px;
    right: 0;
    z-index: 1;
    top: 100%;
  }

  @media (hover: none) {
    .nav-top .megadropdown {
      display: none !important;
      opacity: 0 !important;
      visibility: hidden !important;
    }
  }

  .nav-top .megadropdown span {
    font-size: 18px;
    font-style: italic;
    color: #fff;
    display: block;
    text-align: left;
    width: 490px;
    margin: 0 auto 20px;
  }

  .nav-top .megadropdown__inner {
    background-color: rgba(42, 166, 223, 0.9);
    padding: 0 1000rem 0 1000rem;
    margin: 0 -1000rem;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }

  .nav-top .nav0-2 {
    columns: 3;
    column-gap: 100px;
    margin: 30px auto;
    padding-left: 18px;
  }

  .nav-top .nav0-2 li {
    text-align: left;
  }

  .nav-top .nav0-2 li a {
    -ms-transform: none;
    -webkit-transform: none;
    transform: none;
    font-size: 16px;
    font-weight: 400;
    padding: 10px;
    text-align: left;
    background-color: transparent;
  }

  .nav-top .nav0-2 li a:hover {
    font-weight: 600;
    transition: 400ms;
    color: #FFFFFF !important;
  }

  .nav-top .nav0-2 li:last-of-type a {
    border-bottom: none;
  }
  .desktop-fixed {
    position: fixed;
  }

  .nav-left-fixer {
    position: absolute;
    width: 165px;
    max-width: 225px;
  }

  .nav-left {
    max-width: 225px;
    background-image: -moz-linear-gradient(90deg, rgb(37, 152, 208) 0%, rgb(40, 166, 222) 100%);
    background-image: -webkit-linear-gradient(90deg, rgb(37, 152, 208) 0%, rgb(40, 166, 222) 100%);
    background-image: -ms-linear-gradient(90deg, rgb(37, 152, 208) 0%, rgb(40, 166, 222) 100%);
    color: #fff;
    padding: 25px 10px 40px 10px;
    margin-top: -15px;
  }

  .nav-left a {
    position: relative;
  }

  .nav-left .nav0-2 a:before {
    content: "";
    border-style: solid;
    border-width: 4px 0 4px 6px;
    border-color: transparent transparent transparent #ffffff;
    position: absolute;
    left: -15px;
    opacity: 0;
    top: 5px;
    transition: all 0.2s;
  }

  .nav-left .nav0-2 a:hover:before {
    left: -10px;
    opacity: 1;
  }

  .nav-left li {
    padding: 4px 0;
  }

  .nav-left li.sub > a {
    font-weight: 600;
    font-style: italic;
  }

  .nav-left .nav0-2 a.active:before {
    content: "";
    border-style: solid;
    border-width: 4px 0 4px 6px;
    border-color: transparent transparent transparent #ffffff;
    position: absolute;
    left: -10px;
    opacity: 1;
    top: 5px;
    transition: all 0.2s;
  }

  .nav-left__head {
    font-style: italic;
    font-weight: 600;
    @include font-size(1.5);
    color: #FFF;
    margin-bottom: 10px;
    display: block;
  }

  .nav-left__head:hover {
    color: #FFF;
  }

  .nav-left__shop-head {
    font-style: italic;
    font-weight: 600;
    @include font-size(1.5);
    color: #FFF;
    padding-bottom: 7px;
    margin-bottom: 2px;
    display: block;
    border-bottom: 1px solid #eee;
  }

  .nav-left__shop-login {
    color: #fff;
    font-style: italic;
  }

  .nav-left__shop-head:hover,
  .nav-left__shop-login:hover {
    color: #FFF;
  }

  .nav-left__shop-head + span {
    font-style: italic;
  }

  .nav-left__shop-login {
    color: #fff;
  }

  .white-nav-spacer {
    display: block;
    height: 50px;
  }

  .white-nav-spacer:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 10px;
    background: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .nav-left hr {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .nav-left .nav0-2,
  .nav-left .nav0-3,
  .nav-left .nav0-4,
  .nav-left .nav0-5 {
    position: relative;
    background-color: inherit;
    color: #fff;
    @include font-size(1.3);
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
  }

  .nav-left .nav0-3,
  .nav-left .nav0-4,
  .nav-left .nav0-5 {
    padding-left: 10px;
  }

  .nav-left .nav0-3 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .nav-left .nav0-2 > li.active > a,
  .nav-left .nav0-3 > li.active > a,
  .nav-left .nav0-4 > li.active > a,
  .nav-left .nav0-5 > li.active > a {
    font-style: italic;
    font-weight: 600;
  }

  .nav-left .nav0-2 > li > a,
  .nav-left .nav0-3 > li > a,
  .nav-left .nav0-4 > li > a,
  .nav-left .nav0-5 > li > a {
    color: #fff;
    //line-height: 1.95; //25px
  }

  .nav-left + .triangle {
    border-top: 15px solid transparent;
    border-left: 6px solid darken($brand-primary, 15%);
    position: absolute;
    right: -6px;
    top: -15px;
  }

  .nav-left + .triangle + .triangle {
    border-bottom: 21px solid transparent;
    border-left: 155px solid #2598d0;
    margin-bottom: 50px;
  }

  .global {
    font-size: 12px;
    float: right;
    padding: 8px 0;
    height: 35px;
  }

  .global ul {
    display: flex;
  }

  .global ul li {
    //float: left;
    list-style-type: none;
    padding: 0 0 0 10px;
  }

  .global ul li a {
    position: relative;
  }

  .mobile-header {
    box-shadow: none;
  }

  .container-outer {
    background-color: #fff;
    margin-top: 25px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  }

  .container-outer:not(.prod-cat) {
    margin-bottom: 50px;
  }

  .container-outer.prod-cat {
    margin-top: 0;
    padding: 30px 10px 0;
    box-shadow: none;
  }

  .breadcrumb {
    font-size: 14px;
    margin-bottom: 25px;
  }

  .product-breadcrumb .breadcrumb {
    margin-bottom: 0;
    font-size: 14px;
    padding-left: 0;
  }

  .breadcrumb li a:last-of-type {
    color: #000000;
  }

  .breadcrumb > li:first-of-type {
    &:after {
      content: "/";
      padding: 0 5px;
    }
  }

  .breadcrumb > li:first-of-type {
    padding-right: 8px;
  }

  .product-breadcrumb .breadcrumb > li:first-of-type {
    padding-right: 0;
  }

  .breadcrumb > li:nth-of-type(2)::before {
    content: "";
    padding: 0;
  }
  .breadcrumb > li:last-of-type a {
    color: #2AA6DF;
  }

  #content {
    padding-top: 0;
  }

  .slider__item {
    height: auto;
  }
  .start-slider .slick-arrow {
    top: 47%;
    // transform: scale(1.5);
  }
  .start-slider .slick-dots li button {
    transform: none !important;
  }

  .start-slider .slider__item {

  }
  .start-slider .jumbo-img {
    height: 350px;
  }
  .jumbo-img {
    //position:absolute;
  }

  .jumbo-section {
    background-color: #2AA6DF;
  }

  .start-slider .slick-next {
    right: 30px;
  }
  .start-slider .slick-prev {
    left: 30px;
  }
  .start-slider .slick-dots {
    margin-top: -20px;
    //display: block !important;
  }
  .content-slider {
    margin: 0 -10px 0;
  }
  .content-section {
    margin: 50px 0;
  }
  .box-distinguishes-section {
    margin: 50px 0 0 0;
  }
  .content-section .slick-dots {
    display: none !important;
  }

  .footer ul .head {
    font-size: 18px;
  }
  .head a {
    font-size: 18px;
  }
  .item-title {
    font-size: 18px;
  }
  .Head {
    font-size: 18px;
  }
  footer ul li {
    line-height: 2.1;
  }

  .slider__item .canvas-wrap.left {
    float: left;
    transform: scaleX(-1) scale(0.8) translateX(12.5%);
  }

  .slider__item .canvas-wrap.left .slider-box {
    transform: scaleX(-1);
  }

  .content-slider ul.slick-dots {
    margin-top: 50px;
  }

  //.cert {
  //  justify-content: space-between;
  //}
  //
  //.cert_img {
  //  margin: 10px 0;
  //}
  .cert_img {
    margin: 0;
  }

  .prod-images-right {
    float: right;
    margin-left: 40px;
  }

  .prod-images-right img {
    margin-bottom: 10px;
    display: block;
  }

  .social-media {
    //float: right;
    //margin-top: 36px;
  }

  .prod-detail__ab {
    display: initial;
  }
  .box-section {
    margin: 50px 0;
  }

  .imgmap-luftbild {
    position: relative;
  }

  .imgmap-luftbild #imagemap {
    height: auto;
    width: 100%;
    max-width: 100%;
  }

  .imgmap-luftbild .imgmap-infobox {
    visibility: hidden;
    position: absolute;
    bottom: 10px;
    left: 35%;
    width: 200px;
    transform: scale(0);
    transition: all 0.3s;
    overflow: hidden;
  }

  .imgmap-luftbild .imgmap-infobox .infotext {
    display: block;
    position: absolute;
    left: -500px;
    bottom: 30px;
    height: 22px;
    color: #FFF;
    padding: 2px 15px 2px 6px;
    font-size: 12px;
    background-image: -moz-linear-gradient(90deg, rgb(37, 152, 208) 0%, rgb(40, 166, 222) 100%);
    background-image: -webkit-linear-gradient(90deg, rgb(37, 152, 208) 0%, rgb(40, 166, 222) 100%);
    background-image: -ms-linear-gradient(90deg, rgb(37, 152, 208) 0%, rgb(40, 166, 222) 100%);
    transition: left 0.4s 0.2s;
  }

  .imgmap-luftbild .imgmap-infobox .infotext:after {
    content: "";
    width: 20px;
    height: 22px;
    background-image: -moz-linear-gradient(90deg, rgb(37, 152, 208) 0%, rgb(40, 166, 222) 100%);
    background-image: -webkit-linear-gradient(90deg, rgb(37, 152, 208) 0%, rgb(40, 166, 222) 100%);
    background-image: -ms-linear-gradient(90deg, rgb(37, 152, 208) 0%, rgb(40, 166, 222) 100%);
    -webkit-transform: skewX(-30deg);
    transform: skewX(-30deg);
    position: absolute;
    right: -7px;
    top: 0;
  }

  .imgmap-luftbild .imgmap-infobox .infonumber {
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    width: 26px;
    height: 26px;
    border-radius: 100%;
    background-color: #db2313;
    color: #FFF;
    margin: 0 auto;
    border: 1px solid #FFF;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }
  #imgmap-info1:hover + .imgmap-infobox,
  #imgmap-info2:hover + .imgmap-infobox,
  #imgmap-info3:hover + .imgmap-infobox,
  #imgmap-info4:hover + .imgmap-infobox,
  #imgmap-info5:hover + .imgmap-infobox,
  #imgmap-info6:hover + .imgmap-infobox,
  #imgmap-info7:hover + .imgmap-infobox {
    visibility: visible;
    transform: scale(1);
    z-index: 2;
  }
  #imgmap-info1:focus + .imgmap-infobox,
  #imgmap-info2:focus + .imgmap-infobox,
  #imgmap-info3:focus + .imgmap-infobox,
  #imgmap-info4:focus + .imgmap-infobox,
  #imgmap-info5:focus + .imgmap-infobox,
  #imgmap-info6:focus + .imgmap-infobox,
  #imgmap-info7:focus + .imgmap-infobox {
    visibility: visible;
    transform: scale(1);
    z-index: 1;
  }
  #imgmap-info1:hover + .imgmap-infobox .infotext,
  #imgmap-info2:hover + .imgmap-infobox .infotext,
  #imgmap-info3:hover + .imgmap-infobox .infotext,
  #imgmap-info4:hover + .imgmap-infobox .infotext,
  #imgmap-info5:hover + .imgmap-infobox .infotext,
  #imgmap-info6:hover + .imgmap-infobox .infotext,
  #imgmap-info7:hover + .imgmap-infobox .infotext {
    left: 1px;
  }
  #imgmap-info1:focus + .imgmap-infobox .infotext,
  #imgmap-info2:focus + .imgmap-infobox .infotext,
  #imgmap-info3:focus + .imgmap-infobox .infotext,
  #imgmap-info4:focus + .imgmap-infobox .infotext,
  #imgmap-info5:focus + .imgmap-infobox .infotext,
  #imgmap-info6:focus + .imgmap-infobox .infotext,
  #imgmap-info7:focus + .imgmap-infobox .infotext {
    left: 1px;
  }

  .imgmap-info {
    display: block;
    width: 38px;
    height: 38px;
    background-color: #db2313;
    border: 2px solid #FFF;
    border-radius: 100%;
    color: #FFF;
    font-weight: bold;
    text-align: center;
    font-size: 20px;
    padding-top: 2px;
    position: absolute;
  }

  .imgmap-info:hover,
  .imgmap-info:active,
  .imgmap-info:focus {
    color: #FFF !important;
  }

  #imgmap-info1 {
    top: 70%;
    left: 21%;
  }
  #imgmap-info2 {
    top: 17%;
    left: 20%;
  }
  #imgmap-info3 {
    top: 6%;
    left: 38%;
  }
  #imgmap-info4 {
    top: 7%;
    left: 70%;
  }
  #imgmap-info5 {
    top: 16%;
    left: 77%;
  }
  #imgmap-info6 {
    top: 23%;
    left: 67%;
  }
  #imgmap-info7 {
    top: 31%;
    left: 87%;
  }
  .imgmap-luftbild .filter {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0);
    transition: background-color 1s;
  }
  .imgmap-luftbild .filter.active {
    background-color: rgba(255, 255, 255, 0.5);
  }
  .box-section h1 {
    margin: 10px 0;
  }
  .accordion__inc, .accordion__dec {
    display: none;
  }
  .data-section {
    padding: 0;
    height: 40px;
  }
  .data-section__box {
    display: flex;
    height: 40px;
    justify-content: space-between;
    align-items: center;
  }
  .data-section ul {
    align-items: center;
    margin-top: 0;
    margin-bottom: 0 !important;
  }
  footer .address.contact__menu li {
    font-size: 16px;
  }
  footer .address.contact__menu li.head {
    margin-top: 0;
    line-height: 2.1;
    font-size: 18px;
    margin-bottom: 19px !important;
  }
  footer ul .head .item-title {
    font-weight: 600;
  }

  footer .accordion-list .accordion-list__item {
    margin-bottom: 8px;
  }

  footer .accordion-list .accordion-list__item a {
    font-size: 16px;
  }

  footer .accordion-items {
    margin-bottom: 50px;
  }

  footer .contact__menu {
    margin-left: 0;

    li {
      line-height: 1.3;
    }
  }

  footer .accordion-list .accordion-list__item:last-of-type {
    margin-bottom: 10px;
  }

  .btn--download, .btn--mail {
    display: table;
    //min-width: 300px;
  }

  #theme-nl {
    .content-slider--prod .content-slider__item .prod-img {
      height: 99px;
    }

    .start-slider .jumbo-img, .start-slider .slider__item {
      max-height: 633px;
    }
  }

  .start-slider .slick-next {
    right: 70px;
  }
  .start-slider .slick-prev {
    left: 70px;
  }

  .slider__item .slider-box {
    width: auto;
    display: inline-block;
    padding: 18px 40px;
  }
  .jumbo-section {
    padding-top: 20px;
  }

}
/*
@media (orientation: portrait) {
  .start-slider .jumbo-img,
  .start-slider .slider__item {
    height: 350px !important;
  }
}
*/
@media (orientation: portrait) and (min-width: $screen-desktop) {
  .start-slider .jumbo-img,
  .start-slider .slider__item {
    height: 450px !important;
  }
  .box-section h1 {
    margin: 10px 0;
  }
  .accordion__inc, .accordion__dec {
    display: none;
  }
  .data-section {
    padding: 0;
    height: 40px;
  }
  .data-section__box {
    display: flex;
    height: 40px;
    justify-content: space-between;
    align-items: center;
  }
  .data-section ul {
    align-items: center;
    margin-top: 0;
    margin-bottom: 0 !important;
  }
  footer .address.contact__menu li {
    font-size: 16px;
  }
  footer .address.contact__menu li.head {
    margin-top: 0;
    line-height: 2.1;
    font-size: 18px;
    margin-bottom: 19px!important;
  }
  footer ul .head .item-title {
    font-weight: 600;
  }

  footer .accordion-list .accordion-list__item {
    margin-bottom: 8px;
  }

  footer .accordion-list .accordion-list__item a {
    font-size: 16px;
  }

  footer .accordion-items {
    margin-bottom: 50px;
  }

  footer .contact__menu {
    margin-left: 0;

    li {
      line-height: 1.3;
    }
  }

  footer .accordion-list .accordion-list__item:last-of-type {
    margin-bottom: 10px;
  }

  .btn--download, .btn--mail {
    display: inline-block;
    //min-width: 300px;
  }

  #theme-nl {
    .content-slider--prod .content-slider__item .prod-img {
      height: 99px;
    }
    .start-slider .jumbo-img, .start-slider .slider__item {
      max-height: 633px;
    }
  }

  .start-slider .slick-next {
    right: 70px;
  }
  .start-slider .slick-prev {
    left: 70px;
  }

  .slider__item .slider-box {
    width: auto;
    display: inline-block;
    padding: 18px 40px;
  }
  .jumbo-section {
    padding-top: 20px;
  }

}
/*
@media (orientation: portrait) {
  .start-slider .jumbo-img,
  .start-slider .slider__item {
    height: 350px!important;
  }
}
*/
@media (orientation: portrait) and (min-width: $screen-desktop) {
  .start-slider .jumbo-img,
  .start-slider .slider__item {
    height: 450px!important;
  }
}

/* Mittlere Geräte (Desktop-PCs, 992px und breiter) */
@media (min-width: $screen-desktop) {

  .start-slider .slick-arrow {
    top: 47%;
    transform: scale(1.5);
  }

  .slider__item .slider-box {
    width: 426px;
    float: left;
  }

  .start-slider .jumbo-section__btn.slider-box {
    padding: 14px 30px;
    width: auto !important;
    font-size: 16px;
    height: 40px;

    &:focus, &:hover, &:active {
      color: #FFF;
    }
  }

  .jumbo-section h2 {
    text-align: left;
    margin-bottom: 0;
  }

  .start-slider .jumbo-img {
    position: absolute;
  }

  .slider__item .canvas-wrap {
    max-width: 300px !important;

    &.ol-topleft {
      top: 50px;
      left: 75px;
      right: auto;
      bottom: auto;
    }

    &.ol-topright {
      top: 50px;
      right: 75px;
      left: auto;
      bottom: auto;
    }

    &.ol-bottomleft {
      bottom: 70px;
      left: 75px;
      top: auto;
      right: auto;
    }

    &.ol-bottomright {
      bottom: 70px;
      right: 75px;
      left: auto;
      top: auto;
    }

    &.ol-white {
      h2, h3, h4 {
        color: #FFF !important;
        text-shadow: 0 0 5px #000;
      }
    }

    &.ol-black {
      h2, h3, h4 {
        color: #333 !important;
        text-shadow: 0 0 5px #FFF;
      }
    }

    &.ol-blue {
      h2, h3, h4 {
        color: #2AA6DF !important;
        text-shadow: 0 0 1px #FFF;
      }
    }
  }

  .jumbo-section__btn {
    width: 302px !important;
    text-align: center;
    background-color: $brand-primary;
    color: #FFF;
  }

  .start-slider .jumbo-section {
    padding: 0;
    background-color: transparent;
  }

  .slider__item .canvas-wrap {
    position: absolute;
    bottom: 30px;
    left: 10px;
    text-align: left;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
    height: auto;
    max-width: 623px;

    @media (max-width: 992px) {
      h2 {
        font-size: 24px;
      }
    }
  }

  .scroll2nextsection {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 40px;
    background-color: $brand-primary;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -40px;
    //animation: loopArrow 1.5s linear infinite;

    svg {
      position: relative;
      transform: scale(2);
      top: 0;
      animation: loopArrow 1s linear infinite;
    }
  }

  .start-slider .slick-dots {
    //margin-top: -80px!important;
  }

  .prodlist-image {
    img {
      height: 200px;
    }
  }

  .logo img {
    width: 162px;
  }

  .logoOuter.logo-nl {

    .logo {
      align-items: baseline;
      display: block;
      padding-bottom: 55px;
      width: 100%;
      margin-top: 5px;

      .logo__signalis {
        position: absolute;
        margin: 0;
        height: auto;
        width: 80px;
        left: 120px;
        bottom: 15px;
      }

      .logo__haensch--nl {
        position: absolute;
        margin: 0;
        height: auto;
        width: 90px;
        left: 0;
        bottom: 24px;
      }
    }

    .desktop-fixed .logo__signalis {
      bottom: 10px;
    }

    .desktop-fixed .logo__haensch--nl {
      bottom: 18px;
    }
  }

  .social-media {
    float: none;
    margin-top: 0;
  }

  .nav-top .nav0-1 > ul > li:first-of-type {
    margin-left: 0px;
  }

  .nav-top .nav0-1 > ul > li:nth-last-of-type(2) {
    //margin-right: 20px;
  }

  .nav-top .nav0-1 a {
    display: block;
    @include font-size(1.8);
    padding: 19px 15px;
  }

  .nav-top:before {
    width: 50px;
    left: 30px;
  }

  .nav0-1 {
    margin-left: 0;
    top: inherit;
    left: -45px;
  }

  #theme-nl {
    .nav0-1 {
      padding-left: 45px;

      > ul {
        max-width: 500px;
      }
    }

    .content-slider--prod .content-slider__item .prod-img {
      height: 132px;
    }
  }

  .nav0-1:after {
    right: 75px;
  }

  .nav-top .nav0-1 > ul {
    width: 579px;
  }

  .nav-top:before {
    left: 17px;
  }

  .nav-top .nav0-2 li {
    margin: 0 !important;
    break-inside: avoid-column;
  }

  .nav-top .megadropdown span {
    width: unset;
    margin: 0 0 0 55px;
  }

  .nav-top .nav0-2 {
    width: 700px;
    margin: 30px 0 30px 192px;
  }


  .search--btn {
    position: inherit;
    width: 60px;
  }

  .search--btn img {
    left: 18px;
  }

  .slidein-search button {
    width: 69px;
  }

  .nav-left-fixer {
    width: auto;
  }

  .nav-left {
    padding: 25px 18px 40px 20px;
  }

  .nav-left + .triangle + .triangle {
    border-bottom-width: 28px;
    border-left-width: 210px;
  }

  .slider__item {
    height: 429px;
  }
  .start-slider .slick-dots {
    margin-top: -30px
  }

  .start-slider .slider__item,
  .start-slider .jumbo-img {
    height: 825px;
    height: calc(100vh - 125px);
  }

  .start-slider .jumbo-section h2 {
    font-size: 28px;
    color: #FFF;
    font-weight: 700;
    text-shadow: 0 0 5px #000;
  }

  .start-slider .jumbo-section h4 {
    font-size: 18px;
    color: #FFF;
    font-weight: 600;
    text-shadow: 0 0 5px #000;
  }

  .slider__item .canvas-wrap {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
    position: absolute;
    bottom: 80px;
  }

  .slider__item .canvas-wrap.left {
    transform: scaleX(-1);
  }
  .btn--skew {
    font-size: 18px;

    &:focus {
      border: none !important;
    }
  }
  button:focus {
    border: none !important;
  }

  .content-slider--f .content-slider__item .content-slider__item--text {
    min-height: 160px;
    font-size: 18px;
  }
  .content-slider--f .content-slider__item strong {
    font-size: 20px;
  }
  .prod-detail_image img {
    margin: 0 !important;
  }

  .prod-detail__ab {
    display: block;
  }

  .global--shop li {
    margin-right: 30px;
  }

  .imgmap-luftbild .imgmap-infobox {
    width: 265px;
  }

  .imgmap-luftbild .imgmap-infobox .infotext {
    font-size: 14px;
    padding: 4px 20px 4px 10px;
    height: 28px;
  }

  .imgmap-luftbild .imgmap-infobox .infotext:after {
    height: 28px;
  }

  .imgmap-luftbild .imgmap-infobox .infonumber {
    width: 32px;
    height: 32px;
    padding-top: 3px;
    top: auto;
    left: auto;
    right: 10px;
    bottom: 28px;
  }
  .content-slider--prod .content-slider__item .prod-img,
  .product-cat-list .content-slider__item .prod-img {
    height: 250px;
  }
  .content-slider--prod .slick-next, .box-section .slick-next, .content-slider .slick-next, .content-slider--t .slick-next {
    right: -20px;
  }
  .content-slider--prod .slick-prev, .box-section .slick-prev, .content-slider .slick-prev, .content-slider--t .slick-prev {
    left: -20px;
  }

  .prod__title {
    font-size: 20px;
    font-weight: 500;
  }
  .content-slider--f .content-slider__item--image {
    height: 230px;
  }
  .content-slider--f .slick-arrow {
    top: 40%;
  }
  .jumbo-img {
    height: 429px;
  }

  .content-slider--f .content-slider__item--image,
  .content-slider--f .content-slider__item--image img {
    height: 200px;
  }

}

/* Große Geräte (Desktop-PCs, 1200px und breiter) */
@media (min-width: $screen-lg-desktop) {

  .start-slider .slick-next {
    right: 80px;
  }
  .start-slider .slick-prev {
    left: 80px;
  }

  .prodlist-image {
    img {
      height: 240px;
    }
  }

  .container-outer {
    padding: 0 40px;
    width: $container-large-desktop+60;
  }

  .container-outer.prod-cat {
    padding: 40px 40px 0 40px;
  }

  .nav-top .nav0-2 {
    width: 700px;
    margin: 20px 0 20px 243px;
  }

  .nav0-1 > ul {
    width: 640px;
  }

  .nav-top:before {
    left: 15px;
  }

  .desktop-fixed .logo img {
    transform: scale(1);
    left: 0;
  }

  .logo img {
    transform: scale(1.4);
    position: relative;
    left: 30px;
  }

  .search--btn {
    width: 70px;
  }

  .search--btn img {
    left: 22px;
  }

  .slidein-search button {
    width: 80px;
  }

  .nav-top .nav0-1 a {
    padding: 19px 21px;
  }

  .nav-left + .triangle + .triangle {
    border-bottom-width: 30px;
    border-left-width: 225px;
  }

  .imgmap-luftbild .imgmap-infobox {
    width: 300px;
    left: 31%;
  }
  .content-slider--f .content-slider__item--image img {
    height: auto;
    width: 100%;
  }
  .search .slidein-search {
    width: 280px;
  }

  .content-slider--f .content-slider__item--image {
    height: 230px;
  }

  .nav-left .nav0-2, .nav-left .nav0-3, .nav-left .nav0-4, .nav-left .nav0-5 {
    font-size: 15px;
  }

  #theme-nl .content-slider--prod .content-slider__item .prod-img {
    height: 160px;
  }

  .slider__item {
    display: flex !important;
    justify-content: center;
    background-color: #F1F1F1;

    .jumbo-img {
      //max-width: 1920px;
    }
  }

  .slider__item .canvas-wrap {
    max-width: 400px !important;
  }

}

@media (min-width: 1440px) {

  .start-slider .jumbo-section__btn.slider-box {
    height: 50px;
    font-size: 18px;
    padding: 18px 20px;
  }

  .start-slider .jumbo-section h2 {
    font-size: 36px;
  }

  .start-slider .jumbo-section h4 {
    font-size: 28px;
  }

  .slider__item .canvas-wrap {
    max-width: 450px !important;

    &.ol-topleft {
      top: 80px;
      left: 0;
      right: auto;
      bottom: auto;
    }

    &.ol-topright {
      top: 80px;
      right: 0;
      left: auto;
      bottom: auto;
    }

    &.ol-bottomleft {
      bottom: 100px;
      left: 0px;
      top: auto;
      right: auto;
    }

    &.ol-bottomright {
      bottom: 100px;
      right: 0px;
      left: auto;
      top: auto;
    }
  }
}